import React from "react"

import Header from '../components/PageSection/Header';
import Footer from '../components/PageSection/Footer';
import NotFound from '../components/PageSection/NotFound';
import {graphql} from 'gatsby';

const NotFoundPage = () => {
    return <div id="wrapper">

        <Header page={'notfound'}/>

        <NotFound />

        {/*<div style={{position: 'relative', left: '50vw', paddingLeft: '5rem', marginBottom: '5rem'}}>*/}
        {/*    <Link to={'/'} className={'button'}>Go back</Link>*/}
        {/*</div>*/}

        <Footer page={'notfound'}/>
    </div>
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
