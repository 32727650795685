import React, {FunctionComponent} from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next'

import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'

const NotFound: FunctionComponent = () => {
    const { t } = useTranslation()

    return <Section>
        <SectionHeader title={t('not_found.title')} />
        <div dangerouslySetInnerHTML={{__html: t('not_found.description')}} />
    </Section>
}

export default NotFound
